var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex flex-column fill-height no-hover card-hover",staticStyle:{"min-height":"350px","max-height":"350px"},attrs:{"flat":_vm.flat,"ripple":false},on:{"click":function($event){_vm.isDisplayMoreOpen = true}}},[_c('div',{style:('height: ' +  _vm.imageHeight + 'px')},[(_vm.mediaCount > 0)?_c('v-carousel',{attrs:{"height":_vm.imageHeight,"show-arrows":_vm.mediaCount > 1,"show-arrows-on-hover":"","hide-delimiters":""}},[_vm._l((_vm.getActualityAttachmentYoutubeLinks(_vm.actuality)),function(attachment){return _c('v-carousel-item',{key:attachment.id,staticStyle:{"max-height":"300px"}},[_c('iframe',{attrs:{"width":"100%","height":_vm.imageHeight,"src":_vm.getYoutubeEmbedUrl(attachment.targetFileName),"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])}),_vm._l((_vm.getActualityAttachmentVideoLinks(_vm.actuality)),function(attachment){return _c('v-carousel-item',{key:attachment.id,staticStyle:{"max-height":"300px"}},[_c('video',{attrs:{"controls":"","width":"100%","height":_vm.imageHeight,"src":attachment.targetFileName}})])}),_vm._l((_vm.getActualityAttachmentImages(_vm.actuality)),function(attachment){return _c('v-carousel-item',{key:attachment.id,staticStyle:{"max-height":"300px"}},[_c('v-img',{style:({ 'max-height': _vm.imageHeight }),attrs:{"src":_vm.getAttachmentUrl(attachment)}})],1)})],2):_vm._e()],1),_c('v-toolbar',{staticClass:"flex-grow-0 mt-2",attrs:{"dense":"","flat":"","height":"auto"}},[_c('v-spacer'),_c('v-toolbar-title',{staticClass:"title pb-0 mb-0 primary--text",class:{ 'text--lighten-3': hover }},[(_vm.actuality.isNew)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"yellow","small":""}},on),[_vm._v(" fas fa-star ")])]}}],null,true)},[_vm._v(" Du nouveau ! Publié le "+_vm._s(_vm._f("toDayMonthYearDate")((_vm.actuality.startDate ? _vm.actuality.startDate : _vm.actuality.created)))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.actuality.title)+" ")],1),_c('v-spacer')],1),_c('div',{staticClass:"d-flex align-center mr-4 mb-1"},[(_vm.getActualityAttachmentDocuments(_vm.actuality).length === 1)?_c('v-tooltip',{attrs:{"disabled":!_vm.getActualityAttachmentDocuments(_vm.actuality)[0].title,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"ml-2",attrs:{"color":"red","dark":"","label":"","small":"","href":_vm.getAttachmentUrl(_vm.getActualityAttachmentDocuments(_vm.actuality)[0]),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},on),[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v(" fas fa-paperclip ")]),_vm._v(" "+_vm._s(_vm.getActualityAttachmentDocuments(_vm.actuality).length)+" ")],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.getActualityAttachmentDocuments(_vm.actuality)[0].title)+" ")]):_vm._e(),(_vm.getActualityAttachmentDocuments(_vm.actuality).length > 1)?_c('v-dialog',{attrs:{"width":"40%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"ml-2",attrs:{"color":"red","dark":"","label":"","small":""}},on),[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v(" fas fa-file ")]),_vm._v(" "+_vm._s(_vm.getActualityAttachmentDocuments(_vm.actuality).length)+" ")],1)]}}],null,true),model:{value:(_vm.isDocumentsDialogOpen),callback:function ($$v) {_vm.isDocumentsDialogOpen=$$v},expression:"isDocumentsDialogOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"title primary--text"},[_vm._v(" Documents ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.isDocumentsDialogOpen = false}}},on),[_c('v-icon',[_vm._v(" far fa-times-circle ")])],1)]}}],null,true)},[_vm._v(" Fermer la fenêtre ")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4"},[_c('v-list',_vm._l((_vm.getActualityAttachmentDocuments(_vm.actuality)),function(attachment){return _c('v-list-item',{key:attachment.id,attrs:{"href":_vm.getAttachmentUrl(attachment),"target":"_blank"}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v(" fas fa-file-pdf ")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(attachment.title)+" ")])],1)}),1)],1)],1)],1):_vm._e(),_c('v-spacer'),_c('small',[_vm._v(" "+_vm._s(_vm._f("toDayMonthYearDate")((_vm.actuality.startDate ? _vm.actuality.startDate : _vm.actuality.created)))+" ")])],1),_c('v-card-text',{ref:"contentContainer",staticClass:"pt-0 flex-grow-1 overflow-hidden",staticStyle:{"position":"relative"}},[_c('v-divider'),_c('div',{ref:"content",staticClass:"actuality-content pt-2 text--primary",class:{ 'text--secondary': hover },domProps:{"innerHTML":_vm._s(_vm.actuality.content)}}),(_vm.enableDisplayMore)?_c('div',{staticClass:"d-flex align-end justify-center",staticStyle:{"position":"absolute","bottom":"0","left":"0","width":"100%","height":"200px","background-image":"linear-gradient(to bottom, transparent, white)"}},[_c('span',{staticClass:"text-subtitle-1",staticStyle:{"position":"absolute","z-index":"2"}})]):_vm._e()],1),(_vm.allowModification)?_c('v-card-actions',{staticClass:"align-end flex-grow-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":_vm.getDateInfo('color')}},on),[_vm._v(" "+_vm._s(_vm.getDateInfo('icon'))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.getDateInfo('tooltip'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.getActualityAttachmentDocuments(_vm.actuality).length > 0)?_c('v-chip',_vm._g({staticClass:"mr-2",attrs:{"label":"","small":""}},on),[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v(" fas fa-file ")]),_vm._v(" + "+_vm._s(_vm.getActualityAttachmentDocuments(_vm.actuality).length)+" ")],1):_vm._e()]}}],null,true)},[_vm._v(" Nombre de documents associés ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.actuality.context)?_c('v-chip',_vm._g({attrs:{"label":"","small":""}},on),[_vm._v(" "+_vm._s(_vm.getActualityContext(_vm.actuality.context, 'label'))+" ")]):_vm._e()]}}],null,true)},[_vm._v(" Contexte ")]),_c('v-spacer'),_c('ActualityEditionDialog',{ref:"editionDialog",attrs:{"initActuality":_vm.actuality},on:{"updated":function($event){return _vm.$emit('updated')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"color":"primary","x-small":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fas fa-edit ")])],1)]}}],null,true)},[_vm._v(" Modifier l'actualité ")])]}}],null,true)}),_c('ActualityDeletionDialog',{attrs:{"actuality":_vm.actuality},on:{"deleted":function($event){return _vm.$emit('deleted')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","x-small":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fas fa-trash-alt ")])],1)]}}],null,true)},[_vm._v(" Supprimer l'actualité ")])]}}],null,true)})],1):_vm._e(),_c('v-dialog',{attrs:{"width":"40%"},model:{value:(_vm.isDisplayMoreOpen),callback:function ($$v) {_vm.isDisplayMoreOpen=$$v},expression:"isDisplayMoreOpen"}},[(_vm.isDisplayMoreOpen)?_c('v-card',[_c('v-carousel',{attrs:{"hide-delimiters":"","show-arrows":_vm.mediaCount > 1,"height":"auto","show-arrows-on-hover":""}},[_vm._l((_vm.getActualityAttachmentYoutubeLinks(_vm.actuality)),function(attachment){return _c('v-carousel-item',{key:attachment.id},[_c('iframe',{staticStyle:{"min-height":"500px"},attrs:{"width":"100%","height":"100%","src":_vm.getYoutubeEmbedUrl(attachment.targetFileName),"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])}),_vm._l((_vm.getActualityAttachmentVideoLinks(_vm.actuality)),function(attachment){return _c('v-carousel-item',{key:attachment.id},[_c('video',{attrs:{"controls":"","width":"100%","height":"100%","src":attachment.targetFileName}})])}),_vm._l((_vm.getActualityAttachmentImages(_vm.actuality)),function(attachment){return _c('v-carousel-item',{key:attachment.id,staticStyle:{"text-align":"center"}},[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":_vm.getAttachmentUrl(attachment)}})])})],2),_c('v-card-text',{staticClass:"pt-4"},[_c('h2',{staticClass:"d-flex align-center"},[(_vm.actuality.isNew)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"yellow","small":""}},on),[_vm._v(" fas fa-star ")])]}}],null,true)},[_vm._v(" Du nouveau ! Publié le "+_vm._s(_vm._f("toDayMonthYearDate")((_vm.actuality.startDate ? _vm.actuality.startDate : _vm.actuality.created)))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.actuality.title)+" ")],1),_c('small',[_vm._v(" "+_vm._s(_vm._f("toDayMonthYearDate")((_vm.actuality.startDate ? _vm.actuality.startDate : _vm.actuality.created)))+" ")]),_c('v-divider',{staticClass:"my-2"}),_c('div',{staticClass:"actuality-content",domProps:{"innerHTML":_vm._s(_vm.actuality.content)}}),_c('v-list',_vm._l((_vm.getActualityAttachmentDocuments(_vm.actuality)),function(attachment){return _c('v-list-item',{key:attachment.id,attrs:{"href":_vm.getAttachmentUrl(attachment),"target":"_blank"}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v(" fas fa-file-pdf ")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(attachment.title)+" ")])],1)}),1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.isDisplayMoreOpen = false}}},[_vm._v(" Fermer ")])],1)],1):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }